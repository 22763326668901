<template>
  <div class="goodsdetail-container">
    <div class="auto-flex">
      <!-- <el-form label-width="120px" :model="merchantList" ref="addForm" :inline="true" :hide-required-asterisk="true">
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="客户姓名" prop="title">
                            <div v-if="merchantList.member">{{ merchantList.member.fullname }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="storeType">
                            <div v-if="merchantList.member">{{ merchantList.member.mobile }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="订单数量" prop="storeType">
                            <div v-if="merchantList.delivery_take_order_batch_member">{{ merchantList.delivery_take_order_batch_member.order_number }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="城市仓" prop="name">
                            <div>
                                <span v-if="merchantList.delivery && merchantList.delivery.logistics">{{ merchantList.delivery.logistics.name }}</span>
                                <span v-else>—</span>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="自提点信息" prop="carLicense">
                            <div v-if="merchantList.delivery">{{ merchantList.delivery.name }}，{{ merchantList.delivery.address }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form> -->
      <el-descriptions class="margin-top" :column="3">
        <el-descriptions-item label="客户姓名">
          <div v-if="merchantList.member">
            {{ merchantList.member.fullname }}
          </div></el-descriptions-item
        >
        <el-descriptions-item label="客户电话">
          <div v-if="merchantList.member">
            {{ merchantList.member.mobile }}
          </div></el-descriptions-item
        >
        <el-descriptions-item label="订单数量">
          <div v-if="merchantList.delivery_take_order_batch_member">
            {{ merchantList.delivery_take_order_batch_member.order_number }}
          </div></el-descriptions-item
        >
        <el-descriptions-item label="城市仓">
          <div>
            <span
              v-if="merchantList.delivery && merchantList.delivery.logistics"
              >{{ merchantList.delivery.logistics.name }}</span
            >
            <span v-else>—</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="自提点信息">
          <div v-if="merchantList.delivery">
            {{ merchantList.delivery.name }}，{{
              merchantList.delivery.address
            }}
          </div></el-descriptions-item
        >
        <el-descriptions-item label="客户地址">
          <div>
            {{ $empty.empty(nametisp(merchantList)) }}
          </div></el-descriptions-item
        >
      </el-descriptions>
      <div class="">
        <div class="mb-25" v-for="item in merchantList.orders" :key="item.id">
          <div class="mb-10" v-if="item.order">订单号：{{ item.order_no }}</div>
          <div v-if="item.order">
            <el-table
              :data="item.order.order_detail"
              v-loading="loading"
              :border="true"
              :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
            >
              <el-table-column prop="title" align="center" label="商品名称">
                <template slot-scope="scope">
                  <div>
                    {{ $empty.empty(scope.row.title) }}
                    <span v-if="scope.row.product"
                      >({{ $empty.empty(scope.row.product.sku_code) }})</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="grade" align="center" label="等级">
                <template slot-scope="scope">
                  <span v-if="scope.row.product">{{
                    $empty.empty(scope.row.product.grade)
                  }}</span>
                  <span v-else>—</span>
                </template>
              </el-table-column>
              <el-table-column prop="spec" align="center" label="规格">
                <template slot-scope="scope">
                  <span v-if="scope.row.product">{{
                    $empty.empty(scope.row.product.spec)
                  }}</span>
                  <span v-else>—</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="gross_weight"
                align="center"
                label="毛重(斤)"
              >
                <template slot-scope="scope">
                  <span>{{ $empty.empty(scope.row.gross_weight) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="actual_payment"
                align="center"
                label="毛单价(元)"
              >
                <template slot-scope="scope">
                  <span>{{
                    tool.toDecimal2(
                      (((scope.row.price * 1000) / scope.row.gross_weight) *
                        1000) /
                        100 /
                        1000000
                    )
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="weight" align="center" label="净重(斤)">
                <template slot-scope="scope">
                  <span>{{ $empty.empty(scope.row.weight) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="out_at" align="center" label="净单价(元)">
                <template slot-scope="scope">
                  <span>{{
                    tool.toDecimal2(
                      (((scope.row.price * 1000) / scope.row.weight) * 1000) /
                        100 /
                        1000000
                    )
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" align="center" label="开市价(元)">
                <template slot-scope="scope">
                  <span>{{
                    tool.toDecimal2((scope.row.price * 10000) / 100 / 10000)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="out_at" align="center" label="采购数量">
                <template slot-scope="scope">
                  <span>{{ scope.row.qty }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-footer">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      merchantList: {},
      id: "",
      bigImage: "",
      showImage: false,
      goodsData: [
        //商品信息
      ],
      loading: false,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    nametisp(e) {
      if (e.member) {
        return (
          e.member.province + e.member.city + e.member.area + e.member.address
        );
      }
    },
    // 获取详情
    getDetail() {
      this.$api.delivery
        .batchmemberOrder({
          delivery_take_order_batch_id: this.$route.query.id,
          delivery_take_order_batch_member_id: this.$route.query.member_id,
        })
        .then((res) => {
          this.merchantList = res.data;
          this.goodsData = res.data.order_detail;
        });
    },
  },
};
</script>

<style lang="less">
.goodsdetail-container {
  .auto-flex {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .kv {
    width: 100px;
    height: 100px;
  }

  .sale-font {
    font-size: 15px;
  }

  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }
  .bottom-footer {
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 14px 0;
    box-sizing: border-box;
    justify-content: center;
    margin: 0 -10px;
  }
  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #E65A34;
  // }
}
</style>
