var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goodsdetail-container" }, [
    _c(
      "div",
      { staticClass: "auto-flex" },
      [
        _c(
          "el-descriptions",
          { staticClass: "margin-top", attrs: { column: 3 } },
          [
            _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
              _vm.merchantList.member
                ? _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.merchantList.member.fullname) + " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
              _vm.merchantList.member
                ? _c("div", [
                    _vm._v(" " + _vm._s(_vm.merchantList.member.mobile) + " "),
                  ])
                : _vm._e(),
            ]),
            _c("el-descriptions-item", { attrs: { label: "订单数量" } }, [
              _vm.merchantList.delivery_take_order_batch_member
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.merchantList.delivery_take_order_batch_member
                            .order_number
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("el-descriptions-item", { attrs: { label: "城市仓" } }, [
              _c("div", [
                _vm.merchantList.delivery && _vm.merchantList.delivery.logistics
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.merchantList.delivery.logistics.name)),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "自提点信息" } }, [
              _vm.merchantList.delivery
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.merchantList.delivery.name) +
                        "，" +
                        _vm._s(_vm.merchantList.delivery.address) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("el-descriptions-item", { attrs: { label: "客户地址" } }, [
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$empty.empty(_vm.nametisp(_vm.merchantList))) +
                    " "
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {},
          _vm._l(_vm.merchantList.orders, function (item) {
            return _c("div", { key: item.id, staticClass: "mb-25" }, [
              item.order
                ? _c("div", { staticClass: "mb-10" }, [
                    _vm._v("订单号：" + _vm._s(item.order_no)),
                  ])
                : _vm._e(),
              item.order
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          attrs: {
                            data: item.order.order_detail,
                            border: true,
                            "header-cell-style": {
                              color: "#333333",
                              background: "#EFF6FF",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              align: "center",
                              label: "商品名称",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$empty.empty(scope.row.title)
                                            ) +
                                            " "
                                        ),
                                        scope.row.product
                                          ? _c("span", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.$empty.empty(
                                                      scope.row.product.sku_code
                                                    )
                                                  ) +
                                                  ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "grade",
                              align: "center",
                              label: "等级",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$empty.empty(
                                                  scope.row.product.grade
                                                )
                                              )
                                            ),
                                          ])
                                        : _c("span", [_vm._v("—")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "spec",
                              align: "center",
                              label: "规格",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$empty.empty(
                                                  scope.row.product.spec
                                                )
                                              )
                                            ),
                                          ])
                                        : _c("span", [_vm._v("—")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "gross_weight",
                              align: "center",
                              label: "毛重(斤)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$empty.empty(
                                              scope.row.gross_weight
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "actual_payment",
                              align: "center",
                              label: "毛单价(元)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (((scope.row.price * 1000) /
                                                scope.row.gross_weight) *
                                                1000) /
                                                100 /
                                                1000000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              align: "center",
                              label: "净重(斤)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$empty.empty(scope.row.weight)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "out_at",
                              align: "center",
                              label: "净单价(元)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (((scope.row.price * 1000) /
                                                scope.row.weight) *
                                                1000) /
                                                100 /
                                                1000000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              align: "center",
                              label: "开市价(元)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (scope.row.price * 10000) /
                                                100 /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "out_at",
                              align: "center",
                              label: "采购数量",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.qty)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom-footer" },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }